import React from "react"
import Styles from "./guide.module.scss"
import Mainsection, { MainsectionText } from "src/components/section/main"
import Button from "src/components/LinkedButton"
import Spacer from "src/components/spacer"
import guideImage from "src/images/assets_guide.png"

const Guide = () => {
  const image = (
    <img src={guideImage} alt="ブランドアセットのイメージ" className={`${Styles.isRadius} ${Styles.isFullOnMobile}`} />
  )

  const downloadButton = <Button to={process.env.ASSETS_GUIDE_LINK} title="最新版をダウンロード" />;

  return (
    <Mainsection title="ガイドライン">
      <div className="columns">
        <div className="column is-hidden-tablet has-text-centered">{image}</div>
        <div className="column">
          <MainsectionText>
            <p>natadeCOCOのブランドアセットに関するルールや注意事項を記載しています。<br />ブランドアセット利用時には必ずご確認いただき、ガイドラインに従ってご使用ください。</p>
            <Spacer isHalf />
            <div className="is-hidden-mobile">
              {downloadButton}
            </div>
            <div className="is-hidden-tablet has-text-centered">
              {downloadButton}
            </div>
          </MainsectionText>
        </div>
        <div className={`column is-hidden-mobile has-text-centered ${Styles.isVcentered}`}>{image}</div>
      </div>
    </Mainsection>
  )
}

export default Guide