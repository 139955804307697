import React from "react"
import Styles from "./guide.module.scss"
import Mainsection, { MainsectionText } from "src/components/section/main"
import Subsection from "src/components/section/sub"
import Button from "src/components/LinkedButton"
import Spacer from "src/components/spacer"
import logoImage from "src/images/assets_logo@2x.png"

const Temp = ({ title, icon, img, children }) => {
  const image = (
    <figure className={`image ${Styles.isFullOnMobile}`}>
      <img src={img} alt={title} />
    </figure>
  )
  return (
    <div className="box is-shadowless">
      <Subsection title={title} icon={icon}>
        <div className={`columns ${Styles.feature}`}>
          <div className="column is-hidden-tablet has-text-centered">{image}</div>
          <div className={`column is-half ${Styles.featureText}`}>
            <div className="content">{children}</div>
          </div>
          <div className="column is-1 is-hidden-mobile"></div>
          <div className={`column is-hidden-mobile has-text-centered ${Styles.isVcentered}`}>{image}</div>
        </div>
      </Subsection>
    </div>
  )
}

const Download = () => {
  const downloadButton = <Button to={process.env.ASSETS_LOGO_LINK} title="まとめてダウンロード" />;

  return (
    <Mainsection title="アセット">
      <Temp title="ロゴ" img={logoImage}>
        <MainsectionText>
          <p>natadeCOCOの各種ロゴ、ロゴタイプ、シンボルをダウンロードしていただけます。<br />(PNG画像およびSVGファイル)</p>
          <Spacer isHalf />
          <div className="is-hidden-mobile">
            {downloadButton}
          </div>
          <div className="is-hidden-tablet has-text-centered">
            {downloadButton}
          </div>
        </MainsectionText>
      </Temp>
    </Mainsection>
  )
}

export default Download