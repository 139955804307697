import React from "react"

import Layout from "src/components/layout"
import Seo from "src/components/seo"
import Header from "src/components/header"
import Footer from "src/components/footer"
import Hero from "src/components/assets/hero"
import Guide from "src/components/assets/guide"
import Download from "src/components/assets/download"

const IndexPage = ({ pageContext }) => (
  <Layout>
    <Seo />
    <Header />
    <main>
      <Hero />
      <Guide />
      <Download />
    </main>
    <Footer />
  </Layout>
)

export default IndexPage